
// import Check from '../../utils/check';
const Check = require('../../utils/check');
//基本信息
function getBaseInfoGuideArr(data) {
	var sectionArr = [];
	var dic = {
		TITLE: '',
		CONTENT: ''
	};
	//事项名称
	dic.TITLE = '事项名称';
	dic.CONTENT = handleNull(data.TASK_NAME);
	sectionArr.push(dic);
	//事项类型
	dic = {};
	dic.TITLE = '事项类型';
	dic.CONTENT = handleNull(getTaskType(data.TASK_TYPE));
	sectionArr.push(dic);
	//基本编码
	dic = {};
	dic.TITLE = '基本编码';
	dic.CONTENT = handleNull(data.CATALOG_CODE);
	sectionArr.push(dic);
	//实施编码
	dic = {};
	dic.TITLE = '实施编码';
	dic.CONTENT = handleNull(data.TASK_CODE);
	sectionArr.push(dic);
	//行使层级
	dic = {};
	dic.TITLE = '行使层级';
	dic.CONTENT = handleNull(data.USE_LEVEL_TEXT);
	sectionArr.push(dic);

	if (handleNull(data.TASK_TYPE) != '21') {
		//数量限制
		dic = {};
		dic.TITLE = '数量限制';
		dic.CONTENT = handleNull(data.IS_LIMIT_TEXT);
		sectionArr.push(dic);
	}
	//实施主体
	dic = {};
	dic.TITLE = '实施主体';
	dic.CONTENT = handleNull(data.DEPT_NAME);
	sectionArr.push(dic);
	//实施主体性质
	dic = {};
	dic.TITLE = '实施主体性质';
	dic.CONTENT = handleNull(data.DEPT_TYPE_TEXT);
	sectionArr.push(dic);
	//法定办结时限
	dic = {};
	dic.TITLE = '法定办结时限';
	dic.CONTENT = getData(data.ANTICIPATE_DAY, data.ANTICIPATE_TYPE_TEXT);
	if (Check.isNotNull(data.FDBLSXSM) && data.FDBLSXSM != '无') {
		dic.needLeft = true;
		dic.detail = data.FDBLSXSM;
	}
	sectionArr.push(dic);
	//承诺办结时限
	dic = {};
	dic.TITLE = '承诺办结时限';
	dic.CONTENT = getData(data.PROMISE_DAY, data.PROMISE_TYPE_TEXT);
	if (Check.isNotNull(data.CRBJSXSM) && data.CRBJSXSM != '无') {
		dic.needLeft = true;
		dic.detail = data.CRBJSXSM;
	}
	sectionArr.push(dic);

	//结果名称
	dic = {};
	dic.TITLE = '结果名称';
	dic.CONTENT = handleNull(data.RESULT_NAME);
	sectionArr.push(dic);
	// 结果样本 - 数组转换

	if (Check.isNotNull(data.RESULT_GUID) && data.RESULT_GUID.length > 0) {
		dic = {};
		dic.TITLE = '结果样本';
		dic.fileArray = data.RESULT_GUID;
		dic.isBtn = true;
		dic.CONTENT = data.RESULT_GUID.length == 1 ? data.RESULT_GUID[0].FILENAME : '样本';
		sectionArr.push(dic);
	}


	//办事类型不属于行政许可
	if (handleNull(data.TASK_TYPE) != '1') {
		//办理结果类型
		dic = {};
		dic.TITLE = '办理结果类型';
		dic.CONTENT = handleNull(data.RESULT_TYPE_TEXT);
		sectionArr.push(dic);
	}
	//办件类型
	dic = {};
	dic.TITLE = '办件类型';
	dic.CONTENT = handleNull(data.PROJECT_TYPE_TEXT);
	sectionArr.push(dic);
	//审批服务形式
	dic = {};
	dic.TITLE = '审批服务形式';
	dic.CONTENT = handleNull(data.SERVICE_TYPE_TEXT);
	sectionArr.push(dic);
	//办理形式
	dic = {};
	dic.TITLE = '办理形式';
	dic.CONTENT = handleNull(data.HANDLE_TYPE_TEXT);
	sectionArr.push(dic);
	//业务系统
	dic = {};
	dic.TITLE = '业务系统';
	dic.CONTENT = handleNull(data.XTMC);
	sectionArr.push(dic);
	//支持预约办理
	dic = {};
	dic.TITLE = '支持预约办理';
	dic.CONTENT = handleNull(data.IS_SCHEDULE_TEXT);
	sectionArr.push(dic);
	//支持物流快递
	dic = {};
	dic.TITLE = '支持物流快递';
	dic.CONTENT = handleNull(data.IS_EXPRESS_TEXT);
	sectionArr.push(dic);
	//办事类型不属于公共服务
	if (handleNull(data.TASK_TYPE) != '21') {
		//权力来源
		dic = {};
		dic.TITLE = '权力来源';
		dic.CONTENT = handleNull(data.ITEMSOURCE_TEXT);
		sectionArr.push(dic);
	}
	//联办机构
	dic = {};
	dic.TITLE = '联办机构';
	dic.CONTENT = handleNull(data.OTHER_DEPT);
	sectionArr.push(dic);
	//办事类型不属于行政许可
	if (handleNull(data.TASK_TYPE) != '1') {
		//行使内容
		dic = {};
		dic.TITLE = '行使内容';
		dic.CONTENT = handleNull(data.XSNR);
		sectionArr.push(dic);
	}
	//通办范围
	dic = {};
	dic.TITLE = '通办范围';
	dic.CONTENT = handleNull(data.HANDLE_AREA_TEXT);
	sectionArr.push(dic);

	return sectionArr;
}

//范围条件
function getRangeGuideArr(data) {

	var baseInfoArr = [];
	var sectionArr = [];
	var dic = {
		TITLE: '',
		CONTENT: '',
		NOTITLE: false
	};
	//section 0
	//主题分类
	dic.TITLE = '主题分类';
	dic.CONTENT = handleNull(data.THEME_NATURAL_TYPE_TEXT);
	sectionArr.push(dic);
	//服务对象
	var dic = {};
	dic.TITLE = '服务对象';
	dic.CONTENT = handleNull(data.SERVE_TYPE_TEXT);
	sectionArr.push(dic);
	//申请内容
	var dic = {};
	dic.TITLE = '申请内容';
	dic.CONTENT = handleNull(data.SQNR);
	sectionArr.push(dic);

	var sectionDic = {
		arr: sectionArr,
		sectionTitle: '受理范围',
	}
	baseInfoArr.push(sectionDic);

	//section 1
	sectionArr = [];
	//受理条件
	var dic = {};
	dic.TITLE = '受理条件';
	dic.NOTITLE = true;
	dic.CONTENT = handleNull(data.ACCEPT_CONDITION);
	sectionArr.push(dic);

	var sectionDic = {
		arr: sectionArr,
		sectionTitle: '受理条件',
	}
	baseInfoArr.push(sectionDic);

	return baseInfoArr;
}

//办理流程
function getProcessGuideArr(data) {
	var baseInfoArr = [];
	var sectionArr = [];
	var dic = {
		TITLE: '',
		CONTENT: '',
		NOTITLE: false
	};

	//section 0
	//网上办理流程
	dic.CONTENT = handleNull(data.WSBLLC);
	dic.NOTITLE = true;
	sectionArr.push(dic);
	var sectionDic = {
		arr: sectionArr,
		sectionTitle: '网上办理流程',
		fileArray: data.WSBLLCT,
	}
	baseInfoArr.push(sectionDic);

	//section 1
	sectionArr = [];
	//窗口办理流程
	var dic = {};
	dic.CONTENT = handleNull(data.CKBLLC);
	dic.NOTITLE = true;
	sectionArr.push(dic);
	var sectionDic = {
		arr: sectionArr,
		sectionTitle: '窗口办理流程',
		fileArray: data.CKBLLCT,
	}
	baseInfoArr.push(sectionDic);

	//section 2
	sectionArr = [];
	//特殊环节
	var dic = {};
	dic.CONTENT = handleNull(data.IS_CONTAIN_TSSC_TEXT);
	dic.NOTITLE = true;
	sectionArr.push(dic);

	var sectionDic = {
		arr: sectionArr,
		sectionTitle: '特殊环节',
	}
	baseInfoArr.push(sectionDic);

	return baseInfoArr;
}

//申请材料
function getMatGuideArr(groupArr, matInfo) {
	var length = groupArr.length == 0 ? 1 : groupArr.length;
	var matGuideArr = [];
	for (var i = 0; i < length; i++) {
		var dataArr = [];
		matGuideArr.push(dataArr);
	}
	for (var j = 0; j < matInfo.length; j++) {
		for (var i = 0; i < length; i++) {
			if (matInfo.length == 0) {
				matGuideArr[i].push(matInfo[j]);
			} else {
				if (groupArr[i].ORDERNUMBER == matInfo[j].ORDERNUMBER) {
					matGuideArr[i].push(matInfo[j])
				}
			}
		}
	}
	for (var i = 0; i < matGuideArr.length; i++) {
		matGuideArr[i] = handleMatText(matGuideArr[i]);
	}
	return matGuideArr;
}

//根据情形选择材料数组
function getMatGuideArrByOrder(arr, orderNum) {
	var dataArr = [];
	for (var i = 0; i < arr.length; i++) {
		if (arr[i][0].ORDERNUMBER == orderNum) {
			dataArr = arr[i];
			break;
		}
	}
	return dataArr;
}

//材料数据文本展示处理
function handleMatText(arr) {
	for (var i = 0; i < arr.length; i++) {
		//材料要求
		var pageText = '';
		if (Check.isNotNull(arr[i].PAGE_NUM) && parseInt(arr[i].PAGE_NUM) != 0) {
			pageText = '原件:' + parseInt(arr[i].PAGE_NUM);
		}
		if (pageText != '' && Check.isNotNull(arr[i].PAGE_COPYNUM) && parseInt(arr[i].PAGE_COPYNUM) != 0) {
			pageText = pageText + ';复印件:' + parseInt(arr[i].PAGE_COPYNUM);
		} else if (Check.isNotNull(arr[i].PAGE_COPYNUM) && parseInt(arr[i].PAGE_COPYNUM) != 0) {
			pageText = '复印件:' + parseInt(arr[i].PAGE_COPYNUM);
		}
		if (pageText == '') {
			pageText = '暂无数据';
		}
		arr[i].PAGETEXT = pageText;
		//材料备注
		arr[i].REMARK = Check.isNotNull(arr[i].REMARK) ? arr[i].REMARK : '暂无数据';
		//中介服务
		arr[i].INTERMEDIARYSERVICES = Check.isNotNull(arr[i].INTERMEDIARYSERVICES) ? arr[i].INTERMEDIARYSERVICES : '暂无数据';

	}
	return arr;
}


//咨询监督
function getConsultGuideArr(data) {
	var baseInfoArr = [];
	var sectionArr = [];
	var dic = {
		TITLE: '',
		CONTENT: '',
		NOTITLE: false
	};
	//Section 0
	//咨询方式
	dic.CONTENT = handleNull(data.LINK_WAY);
	dic.NOTITLE = true;
	sectionArr.push(dic);
	var sectionDic = {
		arr: sectionArr,
		sectionTitle: '咨询方式',
	}
	baseInfoArr.push(sectionDic);
	//section 1
	sectionArr = [];
	//监督方式
	var dic = {};
	dic.CONTENT = handleNull(data.SUPERVISE_WAY);
	dic.NOTITLE = true;
	sectionArr.push(dic);
	var sectionDic = {
		arr: sectionArr,
		sectionTitle: '监督方式',
	}
	baseInfoArr.push(sectionDic);

	return baseInfoArr;
}

//法律救济
function getLawGuideArr(data) {
	var baseInfoArr = [];
	var sectionArr = [];
	var dic = {
		TITLE: '',
		CONTENT: '',
		NOTITLE: false
	};
	//section 0
	//部门
	dic.TITLE = '部门';
	dic.CONTENT = handleNull(data.XZFYBM);
	sectionArr.push(dic);
	//地址
	var dic = {};
	dic.TITLE = '地址';
	dic.CONTENT = handleNull(data.XZFYDZ);
	sectionArr.push(dic);
	//电话
	var dic = {};
	dic.TITLE = '电话';
	dic.CONTENT = handleNull(data.XZFYDH);
	sectionArr.push(dic);
	//网址
	var dic = {};
	dic.TITLE = '网址';
	dic.CONTENT = handleNull(data.XZFYWZ);
	sectionArr.push(dic);
	var sectionDic = {
		arr: sectionArr,
		sectionTitle: '行政复议',
	}
	baseInfoArr.push(sectionDic);

	//section 1
	sectionArr = [];
	//部门
	var dic = {};
	dic.TITLE = '部门';
	dic.CONTENT = handleNull(data.XZSSBM);
	sectionArr.push(dic);
	//地址
	var dic = {};
	dic.TITLE = '地址';
	dic.CONTENT = handleNull(data.XZSSDZ);
	sectionArr.push(dic);
	//电话
	var dic = {};
	dic.TITLE = '电话';
	dic.CONTENT = handleNull(data.XZSSDH);
	sectionArr.push(dic);
	//网址
	var dic = {};
	dic.TITLE = '网址';
	dic.CONTENT = handleNull(data.XZSSWZ);
	sectionArr.push(dic);
	var sectionDic = {
		arr: sectionArr,
		sectionTitle: '行政复议',
	}
	baseInfoArr.push(sectionDic);

	return baseInfoArr;
}

// 办事类型
function getTaskType(taskTypeNum) {
	var taskType = '';
	switch (parseInt(taskTypeNum)) {
		case 1:
			taskType = "行政许可";
			break;
		case 2:
			taskType = "行政处罚";
			break;
		case 3:
			taskType = "行政强制";
			break;
		case 4:
			taskType = "行政征收";
			break;
		case 5:
			taskType = "行政给付";
			break;
		case 6:
			taskType = "行政检查";
			break;
		case 7:
			taskType = "行政确认";
			break;
		case 8:
			taskType = "行政奖励";
			break;
		case 9:
			taskType = "行政裁决";
			break;
		case 20:
			taskType = "其他行政权力";
			break;
		case 21:
			taskType = "公共服务";
			break;
		default:
			taskType = "无";
			break;
	}
	return taskType;
}



//无值显示
function handleNull(str) {
	return Check.isNotNull(str) ? str : '暂无数据';
}
//时限显示
function getData(day, type) {
	let temp = handleNull(day);
	if(temp  == '暂无数据') {
		return temp;
	}
	temp = parseInt(temp);
	return  temp + '个' + handleNull(type);
}

module.exports = {
	getBaseInfoGuideArr: getBaseInfoGuideArr,
	getRangeGuideArr: getRangeGuideArr,
	getProcessGuideArr: getProcessGuideArr,
	getConsultGuideArr: getConsultGuideArr,
	getLawGuideArr: getLawGuideArr,
	getMatGuideArrByOrder: getMatGuideArrByOrder,
	getMatGuideArr: getMatGuideArr,
	handleMatText: handleMatText,
}
