<template>
  <div>
    <p ref="contentEl" :class="enabled&&flag?'max-three-line':''" v-html="content"></p>
    <div v-show="enabled&&!hideBtn" class="flex-row flex-center">
      <a class="text-blue" @click="flag = !flag">{{flag?'展开全部':'收起'}}</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "hasMoreDiv",
    props: {
      //内容文本
      content: {
        type: String,
        default: function () {
          return "";
        },
      },
      enabled: {
        type: Boolean,
        default: true
      },
    },
    data() {
      return {
        flag: false, //是否隐藏内容
        hideBtn: false
      };
    },
    mounted() {
      let el = this.$refs.contentEl
      let elHeight = el.offsetHeight
      //三行的高度为88.8
      this.hideBtn = elHeight <= 88.8

      this.flag = true

     /* let that = this
      setTimeout(function () {
        that.$forceUpdate()
      },1000)*/

    },
    beforeMount() {

    },

    methods: {}

  }
</script>

<style scoped>
  .max-three-line {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
</style>
