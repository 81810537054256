let validateUser = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z]).*$/;

let validatePass = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;

let validatePhone = /^\d{11}$/;
let validateShxydm = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
let validateIdcard =
	/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;

const checkUser = function(user) {
	return validateUser.test(user);
}

const checkPass = function(value) {
	return validatePass.test(value);
}

const checkPhone = function(value) {
	return validatePhone.test(value);
}

const checkShxydm = function(value) {
	return validateShxydm.test(value);
}

const checkIdcard = function(value) {
	return validateIdcard.test(value);
}
 // 不为null的则返回true
const isNotNull = function(val) {
	/* 判断是否合法 不为“” null 字符串null undefined */
	if (val != "" && val != null && val != "null" && val != undefined) {
		return true;
	} else {
		return false;
	}
}
module.exports = {
	checkUser,
	checkPass,
	checkPhone,
	checkShxydm,
	checkIdcard,
	isNotNull
}
