<template>
  <div class="material-item">
    <div class="flex-row flex-row-between" style="padding: 10px 0">
      <div class="left ftwt700 max-one-line"
           style="font-size: 16px; color: #333;flex: 1 1 0%;padding-right: 20px">
        <!-- <el-tooltip offset="-2" class="item" effect="dark" placement="top"
                    :content="data.MATERIAL_NAME"> -->
          <strong>{{itemIndex}}.{{data.FORM_GUID.FILENAME}}</strong>
          <!-- <strong>{{itemIndex}}.{{data.MATERIAL_NAME}}</strong> -->
        <!-- </el-tooltip> -->
      </div>

      <a class="text-blue" @click="flag = !flag">{{flag?'展开详情':'收回'}}</a>

    </div>

    <div v-show="!flag" class="twoColumnTable">
      <div class="box">
        <div class="cell-container">

          <div class="cell">
            <div class="title max-one-line">材料形式</div>
            <div class="content max-one-line">{{data.ZZHDZB_TEXT}}</div>
          </div>

          <div class="cell">
            <div class="title max-one-line">材料要求</div>
            <div class="content max-one-line">
              <div class="twoItem">
                <span>{{data.PAGETEXT}}</span>
                <a class="text-blue" @click="showMore(data,'其他要求')">其他要求</a>
              </div>
            </div>
          </div>

          <div class="cell">
            <div class="title max-one-line">来 源</div>
            <div class="content max-one-line">
              <div class="twoItem">
                <span>{{data.SOURCE_TYPE_TEXT}}</span>
                <a class="text-blue" v-if="data.SOURCE_EXPLAIN != '暂无数据'"
                   @click="showMore(data,'来源说明')">来源说明</a>
              </div>
            </div>
          </div>

          <div class="cell">
            <div class="title max-one-line">填报须知</div>
            <div class="content max-one-line"><a @click="showMore(data,'填报须知')">填报须知</a></div>
          </div>

          <div class="cell">
            <div class="title max-one-line">资料下载</div>
            <div class="content">
              <div class="twoItem">
                <a @click="goFile(data.EXAMPLE_GUID,'样表下载')">样表下载</a>
                <a @click="goFile(data.FORM_GUID,'空表')">空表下载</a>
              </div>
            </div>
          </div>

          <div class="cell">
            <div class="title max-one-line">中介服务</div>
            <div class="content max-one-line">
              <p>{{data.INTERMEDIARYSERVICES}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "materialItemDiv",
    props: {
      data: {
        type: Object
      },
      itemIndex: {
        type: Number
      }
    },
    data() {
      return {
        flag: true
      };
    },
    methods: {
      showMore(item, type) {
        let content = '';
        if (type == '其他要求') {
          content = '材料分类：' + (item.MATERIAL_TYPE_TEXT || '暂未分类') + '<br\>' + '材料备注：' + (item.REMARK || "暂无数据");
        } else if (type == '来源说明') {
          content = item.SOURCE_EXPLAIN || "暂无数据";
        } else if (type == '填报须知') {
          content = item.FILL_EXPLIAN || '暂无数据';
        } else if (type == '法定办结时限' || type == '承诺办结时限') {
          content = item.detail;
        }
        this.$alert(content, type, {
          dangerouslyUseHTMLString: true,
          closeOnClickModal: true,
          closeOnPressEscape: true,
        });
      }, goFile(data, type) {
        console.log("样表下载", data)
        let targetHtml = ``
        for (const item of data) {
          targetHtml += `<a href=${item.FILEPATH} target="_blank" referrerpolicy="no-referrer">${item.FILENAME}</a>` + `\n`
        }
        this.$alert((targetHtml || "暂无数据"), type, {
          dangerouslyUseHTMLString: true,
          closeOnClickModal: true,
          closeOnPressEscape: true,
        });
      },
    }
  }
</script>

<style lang="scss" scoped>

  .twoItem {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%
  }

  .twoColumnTable {
    width: 100%;
    margin: 10px 0;
  }

  .cell-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid rgb(226, 226, 226);
    border-left: 1px solid rgb(226, 226, 226);
  }

  .cell {
    width: 49.6%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    color: black;

    .title {
      font-weight: 900;
      background: #eeeeee;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgb(226, 226, 226);
      border-bottom: 1px solid rgb(226, 226, 226);
      height: 36px;
      width: 120px;
      padding: 8px 10px;
      box-sizing: border-box;
    }

    .content {
      flex: 1;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgb(226, 226, 226);
      border-bottom: 1px solid rgb(226, 226, 226);
      padding: 8px 8px;
      height: 36px;
      box-sizing: border-box;
    }
  }
</style>
