<template>
  <div class="guide">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">项目详情</span>
        <span class="banner-fMin banner-other">送政策、送服务，助推企业发展</span>
      </div>
    </div>
    <div ref="guideContainer" class="width-limit guide-container">
      <div>
        <div class="menus">
          <!-- 左侧导航 -->
          <div class="g-left-part">
            <Anchor show-ink :offset-top="offsetTop">
              <AnchorLink
                v-for="(menu, i) in menus"
                :key="i"
                :href="`#${menu.name}`"
                :title="menu.category"
              >
              </AnchorLink>
            </Anchor>
          </div>
          <div class="g-right-part">
            <div class="right-header">
              <!-- 主要标题 -->
              <div class="h-title max-two-line">
                {{ projectName }}
              </div>
              <!-- 申报按钮 -->
              <div class="h-btns">
                <el-button
                  type="primary"
                  @click="gotoDeclare"
                  class="btn-right"
                  >立即申报</el-button
                >
                <el-button disabled type="primary" @click="gotoStreamline" class="btn-right"
                  >查看精简指南
                </el-button>
                 <a href="#shenqingcailiao" class="btn-right" v-if="matInfo && matInfo.length > 0">
                  <el-button type="plain">申报材料</el-button>
                </a>
                <el-button
                  @click="collectOrCancel"
                  :type="collectItemId != '' ? 'warning' : 'plain'"
                  icon="el-icon-star-off"
                  class="btn-right"
                  >{{ collectItemId != "" ? "已关注" : "关注" }}
                </el-button>
              </div>
            </div>
            <div class="flex-row-between menu-content">
              <div class="main-content">
                <GuideCenterShenpi
                  ref="guideCenter"
                  :baseInfo="baseInfo"
                  :rangeInfo="rangeInfo"
                  :processInfo="processInfo"
                  :groupArr="groupArr"
                  :matInfo="matInfo"
                  :consultInfo="consultInfo"
                  :windowInfo="windowInfo"
                  :chargeInfo="chargeInfo"
                  :intermediaryInfo="intermediaryInfo"
                  :shedingyiju="shedingyiju"
                  :lawInfo="lawInfo"
                ></GuideCenterShenpi>
              </div>
              <!-- <div class="other-content">
                <GuideRight :baseItem="baseItem" @gotoDeclare="gotoDeclare"></GuideRight>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GuideCenterShenpi from "./components/guide/guideCenterShenpi";
// import GuideRight from "./components/guide/guideRight";

import Check from "../../utils/check";
import GuideDataCenter from "./guideShenpiDataCenter";
import { Loading } from "element-ui";

export default {
  name: "guide",
  components: {
    GuideCenterShenpi,
    // GuideRight,
  },
  computed: {
    ...mapGetters({
      // childItem: "getCurrentDeclareItem",
      storeguideId: "getCurrentGuideId",
    }),
  },
  data() {
    return {
      disabledDeclare: true,
      projectName: "", // 名称
      offsetTop: 28,
      childItem: undefined,
      /* 注意menus数组中的name要跟*/
      menus: [
        {
          id: 0,
          name: "jibenxinxi",
          category: "基本信息",
        },
        {
          id: 1,
          name: "fanweitiaojian",
          category: "范围条件",
        },
        {
          id: 2,
          name: "banliliucheng",
          category: "办理流程",
        },
        {
          id: 3,
          name: "shenqingcailiao",
          category: "申请材料",
        },
        {
          id: 4,
          name: "zixunjiandu",
          category: "咨询监督",
        },
        {
          id: 5,
          name: "chuangkoubanli",
          category: "窗口办理",
        },
        {
          id: 6,
          name: "xukeshoufei",
          category: "许可收费",
        },
        {
          id: 7,
          name: "zhongjiefuwu",
          category: "中介服务",
        },
        {
          id: 8,
          name: "sheliyiju",
          category: "设立依据",
        },
        {
          id: 9,
          name: "falvjiuji",
          category: "法律救济",
        },
      ],
      // 需要的信息
      baseInfo: undefined, // 基本信息
      rangeInfo: undefined, // 范围条件
      processInfo: [], // 办理流程
      groupArr: [], // 申请材料
      matInfo: [],
      consultInfo: [], // 咨询监督
      windowInfo: [], // 窗口办理
      chargeInfo: [], // 许可收费
      intermediaryInfo: [], // 中介服务
      shedingyiju: [], // 设立依据
      lawInfo: [], // 法律救济
      //collectType 对应 itemType(事项)
      // 4  -> 1 审批类事项
      // 5  -> 2 扶持类事项
      // 6  -> 3 诉求类事项
      collectType: 4, //收藏接口传的type参数
      collectItemId: "",
      itemData: null,
      itemId: "", //当前事项id
	  guideId:"",
	  // 星亮加的，把开发的暂时先弄个列表
	  candoList:["11440306007541934E4440504137000","d41fade3eee34e0988def6846a6dd60e","b19fd48e0025490098dd2d299102a2bd","9e2c791799b7496a94c7a4cd5454901b",
	  "37e8059130ca4801a39bcb9a50830d27","f38bdd26efaf41a99365840a6f3e8f6f"],
    };
  },
  mounted() {
	this.guideId = this.storeguideId;
    this.loadData();
    this.itemId = this.guideId;
    let link = window.location.href;
    let itemType = link.substr(link.length - 1, link.length);
	
	// 获取是否从收藏页面跳转过来，如果是的话有个collectionGuideId
	let collectionGuideId = "";
	let str = "collectionGuideId="
	
	if(link.indexOf(str) != -1){
		let start  = link.indexOf(str)+str.length
		collectionGuideId = link.substr(start,link.length)
	}
	if(collectionGuideId != ""){
		this.guideId = collectionGuideId
	}
	
    if (itemType == 1) {
      this.collectType = 4;
    }
    if (itemType == 2) {
      this.collectType = 5;
    }
    //查询是否收藏
    this.isItemCollect();
  },
  created() {
  	console.log("收藏传参",this.$router.currentRoute.query.collectionGuideId ) 
  },
  methods: {
    loadData() {
      // console.log("guideId:", this.guideId);
      // this.guideId = "11440306574755306X444011100600003";
      // this.guideId = "11440306574755306X4442111808002"
      if (!Check.isNotNull(this.guideId)) {
        //如果ID为null 则返回
        return;
      }
      let params = {
        PERMID: this.guideId,
      };
      let loadingInstance = Loading.service({
        target: this.$refs.guideContainer,
      });
      let localInfo = localStorage.getItem(this.guideId);
	  // 
	  let url = `/dev-api/basicinfo/baseitem/queryBszn?permId=${this.guideId}`
	  if (this.$route.query.isAdded != null) {
		url = `/dev-api/basicinfo/baseitem/queryBsznUrlForAddedSx?permId=${this.guideId}`  
	  }
	
      if (Check.isNotNull(localInfo)) {
        // let data = JSON.parse(localInfo);
        this.childItem = JSON.parse(localInfo || "{}");
        this.handleData(this.childItem, loadingInstance);
		
      } else {
        this.$httpApi
          .get(url)
          .then((res) => {
            console.log("res get item:", res);
            if (res.code != 200) {
              return;
            }
            // let resRetrun = JSON.stringify(res.ReturnValue);

            localStorage.setItem(this.guideId, res.data);
            this.childItem = JSON.parse(res.data || "{}");
            this.handleData(this.childItem, loadingInstance);
          })
		  .catch((err) => {

		  });
        // getStyPermissionByPermid(params, (res) => {});
      }
    },
	// 处理Task_Code
	chickTask_code(CODE){
		for (let i = 0; i < this.candoList.length; i++) { 
			let thisItem = this.candoList[i]
		    if(thisItem == CODE){
				this.disabledDeclare = false
			}
		 }
	},
    /* 处理基本事项 */
    handleData(data, loadingInstance) {
      if (data) {
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
        //  TODO: 暂时开放 商业企业规模成长奖励 可以进入申报 
		// 注释了一行代码，之前只匹配了一个
        // this.disabledDeclare = data.ITEM.TASK_CODE !== "11440306007541934E4440504137000";
		this.chickTask_code(data.ITEM.TASK_CODE)
        this.baseInfo = GuideDataCenter.getBaseInfoGuideArr(data.ITEM); // 基本信息
        this.projectName = this.baseInfo[0].CONTENT;
        this.rangeInfo = GuideDataCenter.getRangeGuideArr(data.ITEM); // 范围条件
        this.processInfo = GuideDataCenter.getProcessGuideArr(data.ITEM); // 办理流程
        this.groupArr = data.CLQX; // 申请材料 
        if (this.groupArr.length == 0) {
          this.matInfo = GuideDataCenter.handleMatText(data.CLXX);
        } else {
          this.selectG = this.groupArr[0];
          this.matArr = GuideDataCenter.getMatGuideArr(this.groupArr, data.CLXX);
          this.matInfo = GuideDataCenter.getMatGuideArrByOrder(
            this.matArr,
            this.selectG.ORDERNUMBER
          );
        }
        this.consultInfo = GuideDataCenter.getConsultGuideArr(data.ITEM); // 咨询监督
        this.windowInfo = data.CKXX; // 窗口信息
        this.chargeInfo = data.XKSF; // 许可收费
        this.intermediaryInfo = data.ZJFW; // 中介服务
        this.shedingyiju = data.SDYJ; // 设立依据
        this.lawInfo = GuideDataCenter.getLawGuideArr(data.ITEM); // 法律救济
        // console.log("item:", this.consultInfo, this.lawInfo);
      }
    },
    /* 转跳到精简办事指南 */
    gotoStreamline() {
      this.$router.push({
        path: "/guideShenpiStreamline",
      });
    },
    gotoDeclare() {
      if (this.$store.getters.getUserInfo) {
        let loadingInstance = Loading.service({
          target: this.$refs.guideContainer,
        });
        this.$httpApi
          .get(`/dev-api/userBusiness/historyBizList`, {
            identify: this.$store.getters.getUserInfo.shxydm,
            sxid: this.guideId,
          })
          .then((res) => {
            loadingInstance.close();
            // if (res.code == 200 && res.rows.length != 0) {
            //   this.showTempDate = [];
            //   let resList = JSON.parse(JSON.stringify(res.rows));
            //   /* 序列化 */
            //   for (const item of resList) {
            //     item.bsdata = JSON.parse(item.bsdata);
            //     item.status = 6; // 待提交设置为6
            //     item.sxmc = item.bsdata.baseinfo.sxmc;
            //     item.showName = "暂存项目";
            //     item.createtime = item.createtime.split("T")[0];
            //     this.showTempDate.push(item);
            //   }
            //   this.dialogTableVisible = true;
            //   return;
            // }
            console.log("chi:", this.childItem);
            this.$store.commit("updateCurrentProjectItem", null);
            this.$store.commit("updateCurrentDeclareItem", this.childItem);
            this.getEnterpriseList();
          })
          .catch((e) => {
            loadingInstance.close();
            this.$message({
              type: "error",
              message: "网络异常，请稍后重试",
            });
          });
      } else {
        this.$router.push({ name: "login" });
      }
    },
    /**
     * 企业用户基本信息列表
     */
    getEnterpriseList() {
      if (this.$store.getters.getUserInfo) {
        let url = "/dev-api/enterprise/baseInfo/list";
        // console.log("idxinxi....", this.$store.getters.getUserInfo)
        let params = {
          pageSize: 100, // 页大小
          pageNum: 0, // 页码
          webEid: this.$store.getters.getUserInfo.id,
        };
        // console.log("企业用户基本信息列表params>>>", params)
        this.$httpApi.get(url, params).then((res) => {
          console.log("判断是否需要引导>>>", res);
          if (res.code === 200 && res.rows.length == 0) {
            // 如果长度为0则需要提示补充基本信息
            this.$confirm("请您完善企业基本信息", "提示", {
              confirmButtonText: "完善",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  path: "/workplace/setting",
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "取消",
                });
              });
          } else if (res.code == 500 || res.code == 50014 || res.msg == '未登录') { 
              this.$store.commit("updateUserInfo", null);
              this.$store.commit("updateToken", null);
              this.$router.push({
                name: "login",
              });
          } else {
            console.log("关闭指引");
            this.$store.commit("updateAccountBaseInfo", res.rows[0]);
            setTimeout(() => {
              this.$router.push({
                name: "declareShenpi",
              });
            }, 300);
          }
        });
      }
    },
    //收藏或取消收藏
    collectOrCancel() {
      if (this.$store.getters.getUserInfo) {
        let flag = this.collectItemId != "";
        if (flag) {
          this.cancelCollect();
        } else {
          this.collect();
        }
      } else {
        this.$router.push({
          name: "login",
        });
      }
    },
    //查询当前是否已被收藏
    isItemCollect() {
      if (this.$store.getters.getUserInfo) {
        let identify = this.$store.getters.getUserInfo.shxydm;
        let id = this.itemId;
        let param = {
          identify: identify,
          collectionid: id,
          type: this.collectType,
          pageNum: 1,
          pageSize: 10,
        };
        console.log("查询是否收藏参数：",param);
        let url = "/dev-api/collection/list";
        this.$httpApi.get(url, param).then((res) => {
          console.log("是否收藏" + JSON.stringify(res));
          if (res.code == 200) {
            if (res.rows.length > 0) {
              this.collectItemId = res.rows[0].id;
            } else {
              this.collectItemId = "";
            }
          }
        })
		.catch((err) => {

		});
      }
    },
    //收藏
    collect() {
      let id = this.itemId;
      let identify = this.$store.getters.getUserInfo.shxydm;
      let name = this.projectName;
      let param = {
        collectionName: name,
        collectionId: id,
        identify: identify,
        type: this.collectType,
      };
      console.log("收藏参数：",param)
      this.$httpApi.post("/dev-api/collection/install", param).then((res) => {
        console.log("添加收藏" + JSON.stringify(res));
        if (res.code == 200 && res.data) {
          this.collectItemId = res.id;
        }
      })
	  .catch((err) => {

	  });
    },
    //取消收藏
    cancelCollect() {
      let collectItemId = this.collectItemId;
      console.log("取消收藏参数：",collectItemId)
      this.$httpApi
        .post("/dev-api/collection/delete/" + collectItemId, {})
        .then((res) => {
          console.log("取消收藏" + JSON.stringify(res));
          if (res.code == 200) {
            this.collectItemId = "";
          }
        })
		.catch((err) => {

		});
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/index.scss";

.banner-size {
  background: url("../../assets/images/icon_banner_declare.png");
}

.banner-info {
  height: 100%;
  display: flex;
  align-items: center;

  .banner-other {
    margin-left: 15px;
  }
}

.guide-container {
  min-height: 500px;
  overflow: hidden;
  background: #ffffff;

  .menus {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .g-left-part {
    width: 120px;
    // padding-left: 30px;
    padding-top: 150px;
  }

  .g-right-part {
    // flex: 1;
    width: 1000px;
    .right-header {
      margin-bottom: 20px;
      margin-left: 30px;

      .h-title {
        padding: 30px 0 10px 0;
        font-size: 24px;
        color: rgb(13, 28, 40);
        font-weight: 600;
        max-width: 700px;
        height: 90px;
        overflow: hidden;
      }

      .btn-right {
        margin-right: 10px;
      }
    }

    .menu-content {
      align-items: flex-start;

      .main-content {
        flex: 1;
        padding-left: 30px;
        padding-right: 30px;
      }

      .other-content {
        width: 260px;
      }
    }
  }
}

.ivu-anchor-link {
  font-size: 15px !important;
  margin-bottom: 6px;
}

.ivu-anchor-link-active {
  font-size: 15px !important;
}

.ivu-anchor-ink-ball {
  width: 3px !important;
  height: 20px !important;
  border: none !important;
  border-radius: 0;
  background-color: #2d8cf0 !important;
}

// .ivu-anchor-link-active {
//   position: relative;
// }
// .ivu-anchor-link-active::after {
//   content: " ";
//   position: absolute;
//   right: 35%;
//   top: 0;
//   width: 3px;
//   height: 30px;
//   background-color: #2d8cf0;
// }
</style>
