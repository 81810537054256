<template>
  <div class="guideCenter">

    <!-- 基本信息 -->
    <item-cell id="jibenxinxi" title="基本信息">
      <TwoColumnTable :items="baseInfo"></TwoColumnTable>
    </item-cell>

    <!-- 范围条件 -->
    <item-cell id="fanweitiaojian" title="范围条件">
      <div>
        <el-collapse>
          <!-- 申请资格 -->
          <el-collapse-item v-for="(fwtjItem, fwtjIndex) in rangeInfo" :key="fwtjIndex"
                            :title="fwtjItem.sectionTitle">
            <div v-for="(fwtj, fwtjIdx) in fwtjItem.arr" :key="fwtjIdx">
              <span class="ftwt700"> {{ `${fwtj.TITLE}:` }} </span> {{ ` ${fwtj.CONTENT}` }}

            </div>
          </el-collapse-item>
        </el-collapse>

      </div>
      <div class="temp-no-data" v-if="!rangeInfo || rangeInfo.length ==0">暂无数据</div>
    </item-cell>

    <!-- 办理流程 -->
    <item-cell id="banliliucheng" title="办理流程">
      <div v-for="(bllcItem, bllcIndex) in processInfo" :key="bllcIndex">
        <div class="item-title">
          {{ bllcItem.sectionTitle }}
        </div>
        <HasMoreDiv v-for="(bllc, bllcIdx) in bllcItem.arr" :key="bllcIdx"
                    :content="bllc.CONTENT" >
        </HasMoreDiv>

      </div>
      <div class="temp-no-data" v-if="!rangeInfo || rangeInfo.length ==0">暂无数据</div>
    </item-cell>

    <!--申请材料 -->
    <item-cell id="shenqingcailiao" title="申请材料">
      <div class="content-wrap">
        <div class="material-group" v-if="groupArr && groupArr.length > 1" @click="selectGroup()">
          <p class="material-group-title">{{selectG.CONDITION_NAME}}</p>
          <image src="../../static/images/icon_arrow_down.png" mode="aspectFit" class="img"></image>
        </div>

        <MaterialItemDiv :data="data" v-for="(data,index) in matInfo" :key="index"
                         :item-index="index+1"></MaterialItemDiv>

        <div class="zanwu-shuju" v-if="!matInfo||matInfo.length==0">
          <div class="tip">暂无数据</div>
        </div>

      </div>
    </item-cell>

    <!-- 咨询监督 -->
    <item-cell id="zixunjiandu" title="咨询监督">
      <div v-for="(zxjdItem, zxjdIndex) in consultInfo" :key="zxjdIndex">
        <p class="item-title">{{zxjdItem.sectionTitle}}</p>
        <div v-for="( zxjd, idx ) in zxjdItem.arr" :key="idx">
          <p>{{ zxjd.CONTENT }}</p>
        </div>
      </div>
      <div class="temp-no-data" v-if="!windowInfo || windowInfo.length ==0">暂无数据</div>
      <!-- 富文本 -->
      <!-- <div v-html="childItem.runflowimg" class="html-box"></div> -->
    </item-cell>

    <!-- 窗口办理 -->
    <item-cell id="chuangkoubanli" title="窗口办理">
      <el-collapse>
        <!-- 申请资格 -->
        <el-collapse-item v-for="(windowItem, windowIndex) in windowInfo" :key="windowIndex"
                          :title="windowItem.NAME">
          <div class="item-cell">
            <div class="left ftwt700">
              办理地点：
            </div>
            <div class="right">
              {{ windowItem.ADDRESS }}
            </div>
          </div>
          <div class="item-cell">
            <div class="left ftwt700">
              办公室电话：
            </div>
            <div class="right">
              {{ windowItem.TEL }}
            </div>
          </div>
          <div class="item-cell">
            <div class="left ftwt700">
              办公时间：
            </div>
            <div class="right">
              {{ windowItem.TIME }}
            </div>
          </div>
          <div class="item-cell">
            <div class="left ftwt700">
              位置指引：
            </div>
            <div class="right">
              {{ windowItem.TIME_DELAY_BUS }}
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>


      <div class="temp-no-data" v-if="!windowInfo || windowInfo.length ==0">暂无数据</div>
    </item-cell>

    <!-- 许可收费 -->
    <item-cell id="xukeshoufei" title="许可收费">
      <div v-for="(data,index) in chargeInfo" :key="index">
        <div class="item-cell">
          <div class="left ftwt700">
            收费项目名称
          </div>
          <div class="right">
            <p>{{data.FEE_NAME}}</p>
          </div>
        </div>
        <div class="item-cell">
          <div class="left ftwt700">
            收费主体
          </div>
          <div class="right">
            <p>{{data.FEE_ORG}}</p>
          </div>
        </div>
        <div class="item-cell">
          <div class="left ftwt700">
            收费性质
          </div>
          <div class="right">
            <p>{{data.FEE_TYPE_TEXT}}</p>
          </div>
        </div>
        <div class="item-cell">
          <div class="left ftwt700">
            收费标准
          </div>
          <div class="right">
            <p>{{data.FEE_STAND}}</p>
          </div>
        </div>
        <div class="item-cell">
          <div class="left ftwt700">
            收费依据名称
          </div>
          <div class="right">
            <p>{{data.LAWNAME}}</p>
          </div>
        </div>
        <div class="item-cell">
          <div class="left ftwt700">
            收费依据文号
          </div>
          <div class="right">
            <p>{{data.ACCORDINGNUMBER}}</p>
          </div>
        </div>

        <div class="item-cell">
          <div class="left ftwt700">
            是否允许减免
          </div>
          <div class="right">
            <p>{{data.IS_DESC_TEXT}}</p>
          </div>
        </div>
        <div class="item-cell">
          <div class="left ftwt700">
            收费减免情形
          </div>
          <div class="right">
            <p>{{data.DESC_EXPLAIN}}</p>
          </div>
        </div>
        <div class="item-cell">
          <div class="left ftwt700">
            条款号及条款内容
          </div>
          <div class="right">
            <p>{{data.TK}}</p>
          </div>
        </div>
      </div>
      <div class="temp-no-data" v-if="!chargeInfo||chargeInfo.length == 0">
        <div class="charge-text right">
          <p>不收费</p>
        </div>
      </div>
    </item-cell>

    <!-- 中介服务 -->
    <item-cell id="zhongjiefuwu" title="中介服务">
      <div class="window-cell" v-for="( zjfwItem, zjfwIndex ) in intermediaryInfo" :key="zjfwIndex">
        <div class="item-cell">
          <div class="left ftwt700">
            事项名称：
          </div>
          <div class="right">
            <p>{{zjfwItem.INTERMEDIARYSERVICES}}</p>
          </div>
        </div>
        <div class="item-cell">
          <div class="left ftwt700">
            事项编码：
          </div>
          <div class="right">
            <p>{{zjfwItem.INTERMEDIARYSERVICESCODE}}</p>
          </div>
        </div>
        <div class="item-cell">
          <div class="left ftwt700">
            详 情：
          </div>
          <div class="right">
            <p>{{zjfwItem.XQ}}</p>
          </div>
        </div>
      </div>
      <div v-if="!intermediaryInfo||intermediaryInfo.length == 0">
        <div class="intermediary-info right">
          无
        </div>
      </div>
    </item-cell>

    <!-- 设立依据 -->
    <item-cell id="sheliyiju" title="设立依据">

      <el-collapse>
        <!-- 申请资格 -->
        <el-collapse-item v-for="(slyjItem, slyjIndex) in shedingyiju" :key="slyjIndex"
                          :title="slyjItem.LAWNAME">
          <div class="box">
            <div class="cell-container">
              <div class="cell">
                <div class="title max-one-line">依据文号</div>
                <div class="content max-one-line">{{slyjItem.ACCORDINGNUMBER}}</div>
              </div>

              <div class="cell">
                <div class="title max-one-line">条款号</div>
                <div class="content max-one-line">{{slyjItem.TERMSNUMBER}}</div>
              </div>
              <div class="cell">
                <div class="title max-one-line">颁布机构</div>
                <div class="content max-one-line">{{slyjItem.BFJG}}</div>
              </div>
              <div class="cell">
                <div class="title max-one-line">实施日期</div>
                <div class="content max-one-line">{{slyjItem.SSRQ}}</div>
              </div>
              <div class="line-cell">
                <div class="title max-one-line">条款内容</div>
                <div class="content ">{{slyjItem.TERMSCONTENT}}</div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>

      <div class="temp-no-data" v-if="!shedingyiju || shedingyiju.length ==0">暂无数据</div>
    </item-cell>

    <!-- 法律救济 -->
    <item-cell id="falvjiuji" title="法律救济">
      <el-collapse>
        <el-collapse-item  v-for="(fljjItem, fljjIndex) in lawInfo" :key="fljjIndex"
                          :title="fljjItem.sectionTitle">
          <p class="fljj-info"
            v-for="( fljj, fljjIdx ) in fljjItem.arr"
            :key="fljjIdx">
            <span class="ftwt700">{{ fljj.TITLE }}：</span>{{ fljj.CONTENT }}
          </p>
        </el-collapse-item>
      </el-collapse>


      <div class="temp-no-data" v-if="!lawInfo || lawInfo.length ==0">暂无数据</div>
    </item-cell>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import ItemCell from "@/components/guideItemCell";
  import TwoColumnTable from "@/components/twoColumnTable";
  import HasMoreDiv from "./hasMoreDiv";
  import MaterialItemDiv from "./materialItemDiv";


  export default {
    name: "guideCenter",
    components: {ItemCell, TwoColumnTable, HasMoreDiv, MaterialItemDiv},
    props: {
      // 基本信息
      baseInfo: {
        type: Array,
        default: function () {
          return []
        }
      },
      // 范围条件
      rangeInfo: {
        type: Array,
        default: () => {
          return []
        }
      },
      // 办理流程
      processInfo: {
        type: Array,
        default: () => {
          return []
        }
      },
      // 申请材料
      groupArr: {
        type: Array,
        default: () => {
          return []
        }
      },
      matInfo: {
        type: Array,
        default: () => {
          return []
        }
      },
      // 咨询监督
      consultInfo: {
        type: Array,
        default: () => {
          return []
        }
      },
      // 窗口办理
      windowInfo: {
        type: Array,
        default: () => {
          return []
        }
      },
      // 许可收费
      chargeInfo: {
        type: Array,
        default: () => {
          return []
        }
      },
      // 中介服务
      intermediaryInfo: {
        type: Array,
        default: () => {
          return []
        }
      },
      // 设立依据
      shedingyiju: {
        type: Array,
        default: () => {
          return []
        }
      },
      // 法律救济
      lawInfo: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
      // ...mapGetters({
      // 	childItem: "getCurrentDeclareItem",
      // }),
    },
    data() {
      return {}
    },
    created() {
    },
    mounted() {
    },
    methods: {
      selectGroup() {
        this.$message({
          message: '功能暂未开放，敬请期待！',
          type: 'warning'
        });
      },
      showMore(item, type) {
        let content = '';
        if (type == '其他要求') {
          content = '材料分类：' + (item.MATERIAL_TYPE_TEXT || '暂未分类') + '<br\>' + '材料备注：' + (item.REMARK || "暂无数据");
        } else if (type == '来源说明') {
          content = item.SOURCE_EXPLAIN || "暂无数据";
        } else if (type == '填报须知') {
          content = item.FILL_EXPLIAN || '暂无数据';
        } else if (type == '法定办结时限' || type == '承诺办结时限') {
          content = item.detail;
        }
        this.$alert(content, type, {
          dangerouslyUseHTMLString: true,
          closeOnClickModal: true,
          closeOnPressEscape: true,
        });

      },
      goFile(data, type) {
        console.log("样表下载", data)
        let targetHtml = ``
        for (const item of data) {
          targetHtml += `<a href=${item.FILEPATH} target="_blank" referrerpolicy="no-referrer">${item.FILENAME}</a>` + `\n`
        }
        this.$alert((targetHtml || "暂无数据"), type, {
          dangerouslyUseHTMLString: true,
          closeOnClickModal: true,
          closeOnPressEscape: true,
        });
      },
    }
  };
</script>

<style lang="scss" scoped>
  .item-title {
    font-size: 16px;
    font-weight: 700;
    color: #333;
  }

  .item-cell {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-content: flex-start;

    .left {
      min-width: 80px;
    }
  }

  .ftwt700 {
    font-weight: 700;
  }

  .guideCenter {
    line-height: 30px;
  }

  .sbfx-items {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

    .sbfx-cell {
      position: relative;
      padding: 16px 10px;
      border: 2px solid #f0f0f0;
      overflow: hidden;
      font-size: 14px;
      line-height: 16px;
      max-width: 350px;

      .sbfx-days-box {
        flex-shrink: 0;
        width: 90px;
        color: #999999;

        .sbfx-days {
          padding: 0 4px;
          color: #fd3938;
        }

        .sbfx-days-end {
          padding: 0 4px;
        }
      }
    }

    .sbfx-cell-on {
      border: 2px solid #4795f1;
    }

    .chooseActive {
      position: absolute;
      bottom: 0px;
      right: -20px;
      border-top: 20px solid #fff;
      border-right: 20px solid #fff;
      border-left: 20px solid #fff;
      border-bottom: 20px solid #4795f1;
      text-align: left;

      .choose-icon {
        position: absolute;
        bottom: -20px;
        color: #fff;
        right: -2px;
      }
    }
  }

  .cailiao-tip {
    margin-top: 20px;
    font-size: 13px;
    color: #000;
    background-color: #f0f0f0;
    border-radius: 6px;
    padding: 16px;
  }

  .temp-no-data {
    padding: 10px 0;

  }

  .twoColumnTable {
    width: 100%;
    margin: 10px 0;
  }

  .cell-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid rgb(226, 226, 226);
    border-left: 1px solid rgb(226, 226, 226);
  }

  .line-cell {
    width: 99.2%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    color: black;
    background: #eeeeee;

    .title {
      font-weight: 900;
      text-align: center;
      border-right: 1px solid rgb(226, 226, 226);
      border-bottom: 1px solid rgb(226, 226, 226);
      width: 120px;
      padding: 8px 10px;
      box-sizing: border-box;
    }

    .content {
      flex: 1;
      background: #ffffff;
      text-align: left;
      border-right: 1px solid rgb(226, 226, 226);
      border-bottom: 1px solid rgb(226, 226, 226);
      padding: 8px 8px;
      box-sizing: border-box;
    }
  }

  .cell {
    width: 49.6%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    color: black;

    .title {
      font-weight: 900;
      background: #eeeeee;
      text-align: center;
      border-right: 1px solid rgb(226, 226, 226);
      border-bottom: 1px solid rgb(226, 226, 226);
      height: 36px;
      width: 120px;
      padding: 8px 10px;
      box-sizing: border-box;
    }

    .content {
      flex: 1;
      background: #ffffff;
      text-align: left;
      border-right: 1px solid rgb(226, 226, 226);
      border-bottom: 1px solid rgb(226, 226, 226);
      padding: 8px 8px;
      height: 36px;
      box-sizing: border-box;
    }
  }
</style>
<style lang="scss">
  .html-box {
    img {
      width: 700px !important;
    }
  }
</style>
